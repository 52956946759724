
import { IEbook } from '@models/ebook/ebook';
import { IEbookPage } from '@models/ebook/page';
import { createAction, props } from '@ngrx/store';

export enum EBOOK_ACTIONS {
  loadBook = '[Ebook] Load Ebook',
  loadPage = '[Ebook] Load Ebook Page',
  setBook = '[Ebook] Set Ebook',
  setPages = '[Ebook] Set Ebook Pages',
  setMessages = '[Ebook] Set Messages',
}

export const loadBook = createAction(EBOOK_ACTIONS.loadBook, props<{ componentId: string, page?: string }>());
export const loadPage = createAction(EBOOK_ACTIONS.loadPage, props<{
  book: IEbook,
  pageIndex: string,
  spread: boolean,
}>());
export const setBook = createAction(EBOOK_ACTIONS.setBook, props<{ book: IEbook }>());
export const setPages = createAction(EBOOK_ACTIONS.setPages, props<{ pages: IEbookPage[] }>());
export const setMessages = createAction(EBOOK_ACTIONS.setMessages, props<{ messages: string[] }>());
