import { IProductComponent } from './product-component';

export interface IProduct {
  skuId: string;
  name: string;
  description: string;
  licenseAssignmentType: string;
  almostExpired?: boolean;
  weight: number;
  components: IProductComponent[];
}

export class ProductSku implements IProduct {
  skuId: string = '';
  name: string = '';
  description: string = '';
  licenseAssignmentType: string = null;
  weight: number = 0;
  components: IProductComponent[] = [];

  constructor(values: IProduct) {
    this.skuId = values.skuId;
    this.name = values.name;
    this.description = values.description;
    this.licenseAssignmentType = values.licenseAssignmentType;
    this.weight = values.weight;
    this.components = values.components;
  }
}

export class Product {
  vid: string;
  uid: string;
  title: string;
  // log: string;
  // status: string;
  comment: string;
  promote: string;
  // sticky: string;
  nid: string;
  type: string;
  // language: string;
  // created: string;
  // changed: string;
  // tnid: string;
  // translate: string;
  // revision_timestamp: string;
  // revision_uid: string;
  // print_display: number;
  // print_display_comment: number;
  // print_display_urllist: number;
  name: string;
  picture: string;
  // data: string;
  // print_pdf_display: number;
  // print_pdf_display_comment: number;
  // print_pdf_display_urllist: number;
  path: string;
  assignmenttype: string[];
  bookid: string;
  booksubid?: any;
  classgrade: string;
  displayname: string;
  // isbn?: any;
  productline: string;
  producttype: string;
  productpath: string[];
  // vendorname: string;
  displayon: string;
  // productasset: ProductAsset;
  productparent?: any;
  weight?: string;
}
