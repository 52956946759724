
import { IEbook } from '@models/ebook/ebook';
import { IEbookPage } from '@models/ebook/page';
import {
  createReducer,
  on,
  Action,
} from '@ngrx/store';

import { loadPage, setBook, setMessages, setPages } from './ebook.actions';

export const ebookStateFeatureKey = 'ebook';

export interface EbookState {
  book: IEbook;
  currentPageIndex: string;
  currentPages: IEbookPage[];
  hasError: boolean;
  messages: string[];
}

export const initialState: EbookState = {
  book: null,
  currentPageIndex: null,
  currentPages: null,
  hasError: false,
  messages: [],
};

const ebookReducer = createReducer(
  initialState,
  on(setBook, (state, { book }) => ({ ...state, book })),
  on(loadPage, (state, { pageIndex }) => ({ ...state, currentPageIndex: pageIndex, currentPages: [] })),
  on(setPages, (state, { pages }) => ({ ...state, currentPages: pages })),
  on(setMessages, (state, { messages }) => ({ ...state, messages, hasError: true })),
);

export function reducer(state: EbookState | undefined, action: Action) {
  return ebookReducer(state, action);
}
