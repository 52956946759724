import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@models/api-response';
import { ProductSku } from '@models/product';
import { IProductComponent } from '@models/product-component';
import { IProductLine } from '@models/product-line';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ProductService {

  constructor(private http: HttpClient) { }

  getProducts(): Observable<ApiResponse<any[]>> {
    return this.http.get(`${environment.apiUrl}/product/navigation`)
      .pipe(
        map((res: ApiResponse<IProductLine[]>) => new ApiResponse<IProductLine[]>(true, {
          response: res.response.map((pl) => {
            const variants = pl.variants.map((variant) => {
              const skus = variant.skus.map(sku => new ProductSku(sku));
              return { ...variant, skus };
            });
            return { ...pl, variants };
          }),
          messages: res.messages,
        })),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<IProductLine[]>(false, err.error));
        })
      );
  }

  getProductComponent(productLines: IProductLine[], componentId: string): [IProductComponent, IProductLine] {
    let productComponent = null;
    let productLine = null;
    productLines.forEach((pl) => {
      pl.variants.forEach((variant) => {
        variant.skus.forEach((sku) => {
          const product = sku.components.find(c => c.componentId === componentId);
          if (product) {
            productLine = pl;
            productComponent = product;
            return [productComponent, productLine];
          }
          return [productComponent, productLine];
        });
      });
    });
    return [productComponent, productLine];
  }
}
